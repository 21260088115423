import React from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';

function ErrorFallback({ error, resetErrorBoundary }) {
  const handleNavigate = (path) => {
    window.location.href = path;
  };

  return (
    <div role="alert">
      <h2>Something went wrong</h2>
      <p>Sorry, there has been an issue. Please try again.</p>
      <pre>{error.message}</pre>
      <button className="ds-btn" type="button" onClick={() => handleNavigate('/')}>
        Try again
      </button>
    </div>
  );
}

function AppErrorBoundary({ children }) {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // Reset any state here if needed
      }}
    >
      {children}
    </ErrorBoundary>
  );
}

ErrorFallback.propTypes = {
  error: PropTypes.string.isRequired,
  resetErrorBoundary: PropTypes.func.isRequired,
};

ErrorFallback.defaultProps = {};

AppErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

AppErrorBoundary.defaultProps = {};

export default AppErrorBoundary;
